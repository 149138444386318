import { Box, chakra, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import ContactForm from '../components/contact/contact-form';
import SEO from '../components/seo';

const ContactPage = ({ data }) => {
  const seoTitle = data.pageSeo.edges[0].node.pageName;
  const seoDescription =
    data.pageSeo.edges[0].node.description.internal.content;
  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      <Flex flexDir="row" w="100%" mt={{ base: 8, lg: 16 }} mb={8}>
        <Flex flexDir="column" w="100%" pr={{ base: 0, lg: 16 }}>
          <Heading fontWeight="bold" as="h1">
            Get in touch
          </Heading>
          <Text mt={4} color="gray.500" fontSize="1.2rem">
            We’d love to hear from you! Call us on{' '}
            <chakra.span color="primary.500" fontWeight="semibold">
              (02) 66 808 910
            </chakra.span>{' '}
            or email us at{' '}
            <chakra.span color="primary.500" fontWeight="semibold">
              info@parentshop.com.au
            </chakra.span>
            , or send us a message using the form below.
          </Text>
          <ContactForm />
        </Flex>
        <Box
          w="100%"
          overflow="hidden"
          borderRadius="md"
          display={{ base: 'none', lg: 'block' }}
        >
          <Img
            image={getImage(data.image.childImageSharp.gatsbyImageData)}
            alt="A seated child reading on a stack of books."
          />
        </Box>
      </Flex>
    </>
  );
};

export default ContactPage;

export const query = graphql`
  query {
    pageSeo: allContentfulPageSeo(filter: { pageName: { eq: "contact" } }) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    image: file(relativePath: { eq: "contact/contact-books.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
  }
`;
